import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import ShopifyTags from '../../utils/shopify-tags'

import logo from "../../assets/images/LaFace_Logo_Warm_Red.svg"

class ShopMobileMenu extends React.Component {

  state = {
    isOpen: new Array(this.props.collections?.length + 1).fill(false),
    length: this.props.collections?.length + 1,
  }

  render() {
    const { menuHandler, active, close, data, collections, subMenu } = this.props

    let { isOpen, length } = this.state

    let props = {
      onClick: (event) => {
        this.setState({ isOpen: new Array(length).fill(false) })
        close(event)
      }
    }

    return (
      <div className={`MobileMenu__Wrapper ${active ? 'active' : ''}`}>
        <div className="MobileMenu__InnerWrapper">
          <button
            type="button"
            className="MobileMenu__CrossIcon"
            onClick={() => menuHandler()}
          >
            <svg width="13px" height="13px" viewBox="0 0 13 13">
              <g stroke="#E84734" strokeLinecap="square" strokeWidth="0.5">
                <line x1="6.5" y1="0" x2="6.5" y2="13" id="Line"></line>
                <line x1="0" y1="6.5" x2="13" y2="6.5" id="Line-2"></line>
              </g>
            </svg>
          </button>
          <div className="header__logo">
            <Link to="/" {...props}>
              <img
                src={logo}
                className="banner__logoImg "
                alt="header-logo"
              />
            </Link>
          </div>

          <Link to='/shop' {...props} className='MobileMenu__shoplink active'>Shop</Link>

          <div className="MobileMenu__divider" />

          <div className='MobileMenu__scroll'>

            { collections.map((el, i) => {
              return (
                <React.Fragment key={i}>
                  <div className={`MobileMenu__element ${isOpen[i] ? 'Services__Menu' : ''}`}>
                    <div className={`${isOpen[i] ? 'Services__head' : 'Services__Menu'}`}>
                      <Link
                        to={`/shop/${el.handle}/`}
                        {...props}>{ el.title }</Link>
                      <div className="Services__Add" onClick={() => {
                        let item = !isOpen[i]
                        isOpen = new Array(length).fill(false)
                        isOpen[i] = item
                        this.setState({ isOpen })
                      }}>
                        <svg className={isOpen[i] ? 'active' : ''} width="13px" height="13px" viewBox="0 0 13 13">
                          <g stroke="#E84734" strokeLinecap="square" strokeWidth="0.5">
                            <line x1="6.5" y1="0" x2="6.5" y2="13" id="Line"></line>
                            <line x1="0" y1="6.5" x2="13" y2="6.5" id="Line-2"></line>
                          </g>
                        </svg>
                      </div>
                    </div>
                    { isOpen[i] && (
                      <ul className="servicesCatagories__list">
                        {
                          ShopifyTags[subMenu[i].handle].map((el, j) => (
                          <li key={j} className="servicesCatagories__listElement">
                            <Link
                              to={`/shop/${subMenu[i].handle}/${el}`}
                              className="servicesCatagories__button"
                              {...props}
                            >
                              {el}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    ) }
                  </div>
                </React.Fragment>
              )
            }) }

            <div className={`MobileMenu__element ${isOpen[length - 1] ? 'Services__Menu' : ''}`}>
              <div className={`${isOpen[length - 1] ? 'Services__head' : 'Services__Menu'}`} onClick={() => {
                  let item = !isOpen[length - 1]
                  isOpen = new Array(length).fill(false)
                  isOpen[length - 1] = item
                  this.setState({ isOpen })
                }}>
                <Link
                  to={`/shop/`}
                  onClick={(event) => event.preventDefault()}>{ 'Brands' }</Link>
                <div className="Services__Add" >
                  <svg className={isOpen[length - 1] ? 'active' : ''} width="13px" height="13px" viewBox="0 0 13 13">
                    <g stroke="#E84734" strokeLinecap="square" strokeWidth="0.5">
                      <line x1="6.5" y1="0" x2="6.5" y2="13" id="Line"></line>
                      <line x1="0" y1="6.5" x2="13" y2="6.5" id="Line-2"></line>
                    </g>
                  </svg>
                </div>
              </div>
              { isOpen[length - 1] && (
                <ul className="servicesCatagories__list">
                  {
                    this.props.brands.slice(0, 7).map((el, j) => (
                    <li key={j} className="servicesCatagories__listElement">
                      <Link
                        to={`/shop/${el.replace(/ /g, '-')}/`}
                        className="servicesCatagories__button"
                        {...props}
                      >
                        {el}
                      </Link>
                    </li>
                  ))}
                </ul>
              ) }
            </div>

          </div>

        </div>
        <div className='MobileMenu__OverlayShop' onClick={menuHandler}></div>
      </div>
    )
  }
}

export default ShopMobileMenu
