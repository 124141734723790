import React from "react"

const Footer = () => {
  return (
    <footer id="footer">
      <div className="container">
        <div className="row content--center ">
          <div className="col col--lg-4 footer__textWrapper">
            <h2>&copy; LA FACE BEAUTY CLINIC</h2>

            <div className="row content--center ">
              <div className="col col--lg-8 footer__social_link">
                <div className="footer__instaLink">
                  <a href="https://www.instagram.com/lafacebeautyclinic" target="_blank">Instagram</a>
                </div>

                <div className="footer__facebookLink">
                  <a href="https://www.facebook.com/LaFaceBeautyClinic/" target="_blank">facebook</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
