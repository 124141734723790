import React, { Component } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StaticQuery, graphql } from "gatsby"
import { scroller } from "react-scroll"

import logo from '../../../static/images/site-logo.png'

class Banner extends Component {

  state = {
    isMobile: true
  }

  componentDidMount() {
    this.resize()
    window.addEventListener('resize', this.resize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  resize = () => {
    this.setState({ isMobile: window && window.innerWidth < 900 })
  }

  render() {
    let { data } = this.props
    let { isMobile } = this.state

    const scrollTo = target => {
      scroller.scrollTo(target, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        isDynamic: true,
      })
    }

    const image = getImage(isMobile ? data.wpPage.acf.landingBannerMobile.localFile : data.wpPage.acf.landingBanner.localFile)

    return (
      <section id="banner-section">
        <GatsbyImage image={image} alt={'La Face Beauty'} />
        <div className="container">
          <div className='banner__top-padding'></div>
          <div className="row content--center">
            <div className="col banner__LogoSec">
              <img
                src={logo}
                className="banner__logo mt-4 responsive--image"
                alt="logo-img"
                onClick={() => scrollTo("header")}
              />
            </div>
          </div>

          <div className="banner__btn_sec">
            <button
              type="button"
              className="banner__btn"
              onClick={() => scrollTo("header")}
            >
              SCROLL DOWN
            </button>
          </div>
        </div>
      </section>
    )
  }
}

export default function BannerWrap(props) {
  return (
    <StaticQuery
      query={graphql`
        query BannerQuery {
          wpPage(slug: {eq: "home"}) {
            acf: homeAcf {
              landingBannerMobile {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: DOMINANT_COLOR 
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              landingBanner {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: DOMINANT_COLOR 
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      `}
      render={data => <Banner data={data} {...props} />}
    />
  )
}
